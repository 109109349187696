<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('部门管理')}}</span>
        <div>
          <el-button type="primary" @click='dialogForm' v-if="user_role['department/add']" size="small">{{$t('新增部门')}}</el-button>
          <!-- <el-button type="primary" size="small">导出EXCEL</el-button> -->
        </div>
      </div>
      <div style="margin:15px 0; width: 250px;">
        <el-input :placeholder="$t('请输入部门名称')" v-model="search" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-input>
      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('部门ID')" prop="id">
        </el-table-column>
        <el-table-column :label="$t('部门名称')" prop="name">
        </el-table-column>
        <el-table-column :label="$t('角色权限')" prop="is_sys">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
            <!-- <el-button type="text" size="small" @click="getRoleDetail(scope)">查看部门</el-button> -->
            <el-button type="text" @click='dialogForm(scope,true)' v-if="user_role['department/edit']" size="small">{{$t('修改部门')}}</el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['department/delete']" size="small">{{$t('删除部门')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('部门操作')" :visible.sync="dialogFormVisible" width="80%">
      <el-row :gutter="20">
        <el-form :model="form" :label-position="getLable" label-width="80px" ref="form">
          <el-col :span="12">
            <el-form-item :label="$t('部门名称')" required prop="name"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="form.name" clearable></el-input>
            </el-form-item>
          </el-col><!-- /部门名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('部门排序')" required prop="sort"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="form.sort" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="JudgmentOperation">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import mixin from '../../utils/mixin.js'
export default {
  name: 'department',
  mixins: [mixin],
  data () {
    return {
      dialogFormVisible: false,
      UntreatedData: [],
      search: '',
      form: {
        id: null,
        name: '',
        sort: 0
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      user_role: {}
    }
  },
  beforeMount () {
    this.getUserList()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
  },
  methods: {
    // 打开表单
    dialogForm (item, isModify) {
      this.isModify = isModify
      if (isModify) {
        // console.log(item)
        this.form = this.$common.forData2(item.row,this.form)
      } else {
        this.form.name = ''
        this.form.sort = 0
      }

      this.dialogFormVisible = true
    },
    // 获取部门列表
    getUserList (page) {
      let params = {
        page: page?page:this.Page.page,
        name: this.search
      }
      this.$axios.post('department/lists', params).then((res) => {
        // console.log(res)
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.UntreatedData.forEach((value) => {
            value.is_sys = value.is_sys === 1 ? this.$t('系统生产') : this.$t('非系统生产')
          })
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        }
      }).catch((error) => console.log(error))
    },
    // 判断执行指令
    JudgmentOperation(){
      if (this.isModify) {
        this.editRole()
      } else {
        this.addRole()
      }
    },
    // 添加部门
    addRole () {
      this.$axios.post('department/add', {
        name: this.form.name,
        sort: this.form.sort
      }).then((res) => {
        if (res.data.code === 0) {
          this.getUserList()
          this.dialogFormVisible = false
          this.$common.resetField('form', this)
        } else console.log(res)
      }).catch((error) => console.log(error))
    },
    // 修改部门
    editRole(){
      let params = this.$common.forData(this.form)
      this.$axios.post('department/edit', params).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.getUserList()
          this.dialogFormVisible = false
          // console.log(res.data.data.roles)
          this.$common.resetField('form', this)
        } else console.log(res)
      }).catch((error) => console.log(error))
    },
    // 删除部门
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('department/delete', {
          id: scope.row.id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else console.log(res)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 获取部门详情
    getRoleDetail (scope) {},
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select {
    width: 100% !important;
  }
</style>
